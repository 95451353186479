import { Link } from "gatsby";
import { PageProps } from "gatsby";
import React from "react";
import { SEO } from "@components";
import { Layout } from "@components/Structural/Landing";

const InternetIssues: React.FC<PageProps> = () => {
  return (
    <>
      <SEO />
      <Layout impactRokin key="Bottom">
        <div style={{
            margin: "7rem 0 7rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>  
          <h1>UPS! SIN INTERNET</h1>
          <h3>CONECTAME!!!</h3>
          <img src="https://media.tenor.com/iDYg-7xD7M4AAAAC/burning-office-spongebob.gif"/>
        </div>
      </Layout>
    </>
  );
};

export default InternetIssues;